import React from "react";
import { graphql } from "gatsby";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { Row } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap-grid.min.css";
import Layout from "../components/layout";

const TermsTemplate = () => {
  const { t } = useTranslation();

  return (
    <Layout>
      <Row className="single-page">
        <div className="entry-content">
          <h1>{t("termsPage.title")}</h1>
          <h4>{t("termsPage.definitions.title")}</h4>
          <p
            dangerouslySetInnerHTML={{
              __html: t("termsPage.definitions.paragraphOne"),
            }}
          ></p>
          <p
            dangerouslySetInnerHTML={{
              __html: t("termsPage.definitions.paragraphTwo"),
            }}
          ></p>
          <p
            dangerouslySetInnerHTML={{
              __html: t("termsPage.definitions.paragraphThree"),
            }}
          ></p>
          <p
            dangerouslySetInnerHTML={{
              __html: t("termsPage.definitions.paragraphFour"),
            }}
          ></p>
          <h4>{t("termsPage.entryContent.titleOne")}</h4>
          <ol
            dangerouslySetInnerHTML={{
              __html: t("termsPage.entryContent.paragraphOne"),
            }}
          ></ol>
          <h4>{t("termsPage.entryContent.titleTwo")}</h4>
          <ol
            dangerouslySetInnerHTML={{
              __html: t("termsPage.entryContent.paragraphTwo"),
            }}
          ></ol>
          <h4>{t("termsPage.entryContent.titleThree")}</h4>
          <ol
            dangerouslySetInnerHTML={{
              __html: t("termsPage.entryContent.paragraphThree"),
            }}
          ></ol>
          <h4>{t("termsPage.entryContent.titleFour")}</h4>
          <ol
            dangerouslySetInnerHTML={{
              __html: t("termsPage.entryContent.paragraphFour"),
            }}
          ></ol>
          <h4>{t("termsPage.entryContent.titleFive")}</h4>
          <ol
            dangerouslySetInnerHTML={{
              __html: t("termsPage.entryContent.paragraphFive"),
            }}
          ></ol>
          <h4>{t("termsPage.entryContent.titleSix")}</h4>
          <ol
            dangerouslySetInnerHTML={{
              __html: t("termsPage.entryContent.paragraphSix"),
            }}
          ></ol>
          <h4>{t("termsPage.entryContent.titleSeven")}</h4>
          <ol
            dangerouslySetInnerHTML={{
              __html: t("termsPage.entryContent.paragraphSeven"),
            }}
          ></ol>
        </div>
      </Row>
    </Layout>
  );
};
export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: { ns: { in: ["common", "index"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
export default TermsTemplate;
